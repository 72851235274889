import * as React from "react";
import "./styling/App.scss";
import "./styling/index.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import DataImport from "./pages/import/DataImport";
import Home from "./pages/home/Home";
import { GradesContextProvider } from "./contexts/GradesContext";
import ParentsImport from "./pages/import/ParentsImport";

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        {/* <nav>
          <Link to="/">Home</Link>
          &nbsp;
          <Link to="/import">Import</Link>
          &nbsp;
          <Link to="/parent-import">Parent Import</Link>
        </nav> */}
        <header className="App-header">
          <div>
            <img
              src="un-4-education.svg"
              alt="FN verdensmål - Kvalitetsuddannelse"
              width="80"
            />
          </div>
          <div className="title">4 | Kvalitetsuddannelse</div>
        </header>
        <div>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/import">
              <DataImport />
            </Route>
            <Route path="/parent-import">
              <ParentsImport />
            </Route>
            <Route path="/">
              <GradesContextProvider>
                <Home />
              </GradesContextProvider>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
